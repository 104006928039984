import Carousel from './modules/carousel';
import Global from './modules/global';
import Newsletter from './modules/newsletter';

const modules = {
  Carousel,
  Global,
  Newsletter
};

var elements = document.querySelectorAll('.joomla-script-options');
if (elements.length > 0) {
  var options = JSON.parse(elements[0].text || elements[0].textContent);
  if (options.modules) {
    options.modules.forEach((module) => {
      if (modules[module.name]) {
        new modules[module.name](module.options);
      }
    });
  }
}
