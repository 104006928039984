
class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.run();
  }

  run() {
    this.menu();
  }

  menu() {
    var burgers = document.querySelectorAll('.rich-menu');
    burgers.forEach((burger) => {
      burger.addEventListener('click', function (event) {
        event.preventDefault();
        this.classList.toggle('is-open');
        document.body.classList.toggle('menu-opened');
      });
    });
  }
}

export {
  Global as
    default
};
